import React from "react";
import loadable from "@loadable/component";
import { designFAQList } from "../components/UIAndUXDesign/components/FAQSection/FAQSection";

const Layout = loadable(() => import("@common/Layout/Layout"));
const UIAndUXDesign = loadable(() => import("@components/UIAndUXDesign"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const UIAndUXDesignPage = ({ path }) => (
    <>
        <SEO
            title="Flawless UI/UX Design Services and Best Professional Designers"
            titleTemplate="Impressit"
            description="We offer exceptional UI/UX design services — hire UI UX designer with Impressit and get functional and user friendly design for your app!"
            path={path}
            faqSchema={designFAQList}
        />
        <Layout path={path}>
            <UIAndUXDesign />
        </Layout>
    </>
);

export default UIAndUXDesignPage;
